import type { SyntheticEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Column, Columns, Link, Typography, useToast } from "@aviary";
import { isEmailAtValid, isLengthValid } from "@shared/components/EmailInput";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { useLocation } from "@shared/react-router-dom";
import { StorePlatform } from "@shared/types/StorePlatform";
import type {
  AuthUserSignInError,
  EmersonAuthUserSignInError,
  TwoFactorAuthenticationMethods,
} from "@shared/types/graphqlGenerated";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import { useUserSignIn } from "@unauthenticated/shared/data/mutations";
import { useEmersonUserSignIn } from "@unauthenticated/shared/data/mutations/EmersonUserSignIn.mutation";
import { e2eAttributes } from "@unauthenticated/shared/e2eAttributes";
import { l } from "@unauthenticated/shared/locales/i18n";

import { RecaptchaErrorBox } from "../RecaptchaErrorBox";
import { UnauthorizedErrorBox } from "../UnauthorizedErrorBox";

import { SignInEmailInput } from "./SignInEmailInput";
import { SignInPasswordInput } from "./SignInPasswordInput";

import * as styles from "./SignInForm.styles";

interface TwoFactorData {
  otpMethod: TwoFactorAuthenticationMethods;
  userEmail: string;
  phoneLast4: string;
  authToken: string;
}

interface Props {
  additionalAttributes?: any;
  setHasInputErrors?: (value: boolean) => void;
  forgotPasswordLink: string;
  onCompleted?: any;
  isOAuth?: boolean;
  defaultEmail?: string;
  onShowTwoFactorFlow?: (data: TwoFactorData) => void;
  isIntake?: boolean;
}

const SignInForm = ({
  additionalAttributes = {},
  setHasInputErrors,
  forgotPasswordLink,
  onCompleted,
  isOAuth,
  defaultEmail,
  onShowTwoFactorFlow,
  isIntake,
}: Props) => {
  const { search } = useLocation();
  const urlEmail = new URLSearchParams(search).get("email");
  const captchaBypassToken = new URLSearchParams(search).get("captchaBypassToken");

  const { t } = useTranslation();
  const { storePlatform } = useSharedGlobalConfig();

  const [email, setEmail] = useState(urlEmail || defaultEmail || "");
  const [password, setPassword] = useState("");
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [isPasswordDirty, setIsPasswordDirty] = useState(false);
  const [isCredentialsIncorrect, setIsCredentialsIncorrect] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);

  const { makeToast } = useToast();
  const { tablet } = useBreakpoints();

  const isEmailAutoFocus = !urlEmail && !defaultEmail && tablet.greaterThan;
  const isPasswordAutoFocus = !isEmailAutoFocus;

  const handleMutationErrors = (errors: EmersonAuthUserSignInError | AuthUserSignInError) => {
    if (
      errors?.message?.includes("credentials") ||
      errors?.message?.match(/Could not find user/i) ||
      errors?.message?.includes("Emerson Wholesale not enabled")
    ) {
      if (setHasInputErrors) setHasInputErrors(true);
      setIsCredentialsIncorrect(true);
    }

    if (errors?.message?.match(/Captcha/i)) {
      makeToast("error", t(l.signIn.CaptchaError));
    }

    if (errors?.message?.match(/EmersonSignIn/i)) {
      makeToast("error", t(l.signIn.EmersonCaptchaError));
    }

    setAuthError(errors?.message?.includes("authorized"));
  };

  const handle2FA = (authToken, otpMethod, userEmail, phoneLast4) => {
    if (authToken && onShowTwoFactorFlow) {
      onShowTwoFactorFlow({
        otpMethod,
        userEmail,
        phoneLast4,
        authToken,
      });
    }
  };

  const fullscriptMutationOptions = {
    onCompleted: completedData => {
      const {
        errors,
        redirectPath,
        twoFactorAuthenticationToken: authToken,
        otpMethod,
        userEmail,
        phoneLast4,
      } = completedData?.auth?.userSignIn;

      handleMutationErrors(errors);
      handle2FA(authToken, otpMethod, userEmail, phoneLast4);

      if (onCompleted) {
        onCompleted(completedData);
      } else {
        if (redirectPath) {
          window.location.assign(redirectPath);
        }
      }
    },
    onError: ({ message }) => {
      makeToast("error", message);
    },
  };

  const emersonMutationOptions = {
    onCompleted: completedData => {
      const {
        errors,
        redirectPath,
        twoFactorAuthenticationToken: authToken,
        otpMethod,
        userEmail,
        phoneLast4,
      } = completedData?.auth?.emersonSignIn;

      handleMutationErrors(errors);

      if (onCompleted) {
        onCompleted(completedData);
      } else {
        if (redirectPath) {
          window.location.assign(redirectPath);
        }
        handle2FA(authToken, otpMethod, userEmail, phoneLast4);
      }
    },
    onError: ({ message }) => {
      makeToast("error", message);
    },
  };

  const [signInUser, { loading: isSignInLoading }] = useUserSignIn(fullscriptMutationOptions);

  const [signInEmersonUser, { loading: isEmersonSignInLoading }] =
    useEmersonUserSignIn(emersonMutationOptions);

  const signInMutationVariables = (captchaToken: string) => ({
    variables: {
      input: {
        captchaToken,
        attributes: {
          email,
          password,
          rememberMe,
          optional: { ...additionalAttributes, storePlatform },
          intake: isIntake,
        },
      },
    },
  });

  const getPasswordPrompt = () => {
    if (!urlEmail && !defaultEmail) return null;

    return <Typography type="footnote">{t(l.common.PasswordPrompt)}</Typography>;
  };

  const handleEmailOnBlur = () => {
    if (email) {
      setIsEmailDirty(true);
    }
  };

  const handleEmailOnChange = e => {
    setIsCredentialsIncorrect(false);
    setEmail(e.target.value);
    setIsEmailDirty(false);
  };

  const handlePasswordOnChange = e => {
    setPassword(e.target.value);
    setIsCredentialsIncorrect(false);
    setIsPasswordDirty(false);
  };

  const handlePasswordOnBlur = () => {
    if (password) {
      setIsPasswordDirty(true);
    }
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    const isEmailValid = isEmailAtValid(email) && isLengthValid(email);
    const isPasswordValid = !!password;
    const isValid = isEmailValid && isPasswordValid && !isCredentialsIncorrect;

    const userSignIn = (captchaToken: string) => {
      storePlatform === StorePlatform.EMERSON
        ? signInEmersonUser(signInMutationVariables(captchaToken))
        : signInUser(signInMutationVariables(captchaToken));
    };

    if (isValid && captchaBypassToken) {
      userSignIn(captchaBypassToken);
    } else if (isValid) {
      gRecaptchaExecute(captchaToken => {
        userSignIn(captchaToken);
      }, setRecaptchaError);
    }
    !isLengthValid(email) ? setIsEmailDirty(true) : setIsPasswordDirty(true);
  };

  const renderForgotPasswordLink = () => {
    const fullLink = `${forgotPasswordLink}?email=${encodeURIComponent(email)}` as const;

    if (isOAuth) {
      history.pushState({ target: "signin" }, "", window.location.href);

      return (
        <Link href={fullLink} isColor="system">
          {t(l.signIn.ForgotPassword)}
        </Link>
      );
    } else {
      return (
        <Link to={fullLink} isColor="system">
          {t(l.signIn.ForgotPassword)}
        </Link>
      );
    }
  };

  const renderCheckbox = () => {
    if (storePlatform === StorePlatform.FULLSCRIPT) {
      return (
        <div css={styles.checkboxWrapper}>
          <Checkbox checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}>
            <Typography>{t(l.signIn.StaySignedIn)}</Typography>
          </Checkbox>
        </div>
      );
    }
  };

  return (
    <>
      {authError && <UnauthorizedErrorBox />}
      {recaptchaError && <RecaptchaErrorBox />}
      <form noValidate css={styles.form} onSubmit={handleSubmit}>
        <SignInEmailInput
          required
          value={email}
          onChange={handleEmailOnChange}
          isDirty={isEmailDirty}
          onBlur={handleEmailOnBlur}
          descriptiveText={getPasswordPrompt()}
          autoFocus={isEmailAutoFocus}
          wrapperStyles={styles.marginBottom}
        />
        <SignInPasswordInput
          isCredentialsIncorrect={isCredentialsIncorrect}
          required
          onChange={handlePasswordOnChange}
          value={password}
          isDirty={isPasswordDirty}
          onBlur={handlePasswordOnBlur}
          isLoading={false}
          autoFocus={isPasswordAutoFocus}
          wrapperStyles={styles.marginBottom}
          data-e2e={e2eAttributes.login.signInPassword}
        />
        {renderCheckbox()}
        <Columns isMarginless css={styles.row}>
          <Column isPaddingless columnWidth={6}>
            <Typography css={styles.textLeft}>{renderForgotPasswordLink()}</Typography>
          </Column>
          <Column isPaddingless columnWidth={6}>
            <Button
              isLoading={
                storePlatform === StorePlatform.EMERSON ? isEmersonSignInLoading : isSignInLoading
              }
              isFullWidth
              type="submit"
              data-e2e={e2eAttributes.login.signInButton}
            >
              {t(l.signIn.SignIn)}
            </Button>
          </Column>
        </Columns>
      </form>
    </>
  );
};

export { SignInForm };
